import { api } from '../../api'
import { NotificationChannel, NotificationType } from '../../notifications/notifications'
import { makeOrganisationApiEndpoints } from './endpoints'
import { CheckBillingSettingsViewModel, OrganisationViewModel, makeCheckBillingSettingsViewModel } from './organisation'
import { CheckBillingSettingsResponse } from './organisation-response'
import { ProjectTypeViewModel } from './project-type'

const apiEndpoints = makeOrganisationApiEndpoints()

export const organisationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganisationById: builder.query<OrganisationViewModel, string>({
      query: (id) => apiEndpoints.organisationItem(id),
      providesTags: ['Organisation'],
    }),
    checkBillingSettings: builder.query<CheckBillingSettingsViewModel, string>({
      query: (id) => apiEndpoints.orgBillingSettingsCheck(id),
      transformResponse: (checkBillingSettings: CheckBillingSettingsResponse) => {
        return makeCheckBillingSettingsViewModel(checkBillingSettings)
      },
    }),
    updateOrgNotificationSettings: builder.mutation<
      OrganisationViewModel,
      { orgId: string } & OrgNotificationSettingsUpdateData
    >({
      query: ({ orgId, ...updateData }) => ({
        url: apiEndpoints.orgNotificationSettings(orgId),
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['Organisation'],
    }),
    getProjectTypes: builder.query<ProjectTypeViewModel[], string>({
      query: (id) => apiEndpoints.projectTypes(id),
      providesTags: ['ProjectType'],
    }),
    addProjectType: builder.mutation({
      query: ({ orgId, type }: NewProjectTypeData & { orgId: string }) => ({
        url: apiEndpoints.projectTypes(orgId),
        method: 'POST',
        body: { type },
      }),
      invalidatesTags: ['ProjectType'],
    }),
    updateProjectType: builder.mutation({
      query: ({ orgId, id, ...updateData }: ProjectTypeUpdateData & { orgId: string; id: string }) => ({
        url: apiEndpoints.projectTypeItem(id, orgId),
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['ProjectType'],
    }),
    deleteProjectType: builder.mutation({
      query: ({ orgId, id }: { orgId: string; id: string }) => ({
        url: apiEndpoints.projectTypeItem(id, orgId),
        method: 'DELETE',
      }),
      invalidatesTags: ['ProjectType'],
    }),
  }),
})

export const {
  useGetOrganisationByIdQuery,
  useCheckBillingSettingsQuery,
  useUpdateOrgNotificationSettingsMutation,
  useGetProjectTypesQuery,
  useAddProjectTypeMutation,
  useUpdateProjectTypeMutation,
  useDeleteProjectTypeMutation,
} = organisationApi

export type OrgNotificationSettingsUpdateData = {
  enable: NotificationUpdateAction[]
  disable: NotificationUpdateAction[]
}

export type NewProjectTypeData = {
  type: string
}

type NotificationUpdateAction = { type: NotificationType; channel: NotificationChannel }
export type ProjectTypeUpdateData = {
  type?: string
  isVisibleInOptions?: boolean
  isDefault?: boolean
}
