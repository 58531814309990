import * as organisationApi from '../api'
import { useSnackbar } from 'notistack'
import { useUpdateOrgNotificationSettingsMutation } from '../api'
import { NotificationChannel, NotificationType } from '../../notifications/notifications'

export const useOrgMutations = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [updateNotificationSettingsMutation] = useUpdateOrgNotificationSettingsMutation()
  const [addProjectTypeMutation] = organisationApi.useAddProjectTypeMutation()
  const [updateProjectTypeMutation] = organisationApi.useUpdateProjectTypeMutation()
  const [deleteProjectTypeMutation] = organisationApi.useDeleteProjectTypeMutation()

  const handleError = (error: any) => {
    const errorMessage = 'error' in error ? error.error : 'message' in error ? error.message : 'Unknown error'
    enqueueSnackbar(errorMessage, { variant: 'error' })
  }

  const enableNotification = (orgId: string, type: NotificationType, channel: NotificationChannel) => {
    const updateData = { enable: [{ type, channel }], disable: [] }
    return updateNotificationSettings(orgId, updateData)
  }

  const disableNotification = (orgId: string, type: NotificationType, channel: NotificationChannel) => {
    const updateData = { enable: [], disable: [{ type, channel }] }
    return updateNotificationSettings(orgId, updateData)
  }

  const updateNotificationSettings = async (orgId: string, data: organisationApi.OrgNotificationSettingsUpdateData) => {
    return await updateNotificationSettingsMutation({ orgId, ...data })
      .unwrap()
      .catch(handleError)
  }

  const addProjectType = async (orgId: string, type: string) => {
    return await addProjectTypeMutation({ orgId, type }).unwrap().catch(handleError)
  }

  const updateProjectType = async (
    orgId: string,
    projectTypeId: string,
    updateData: organisationApi.ProjectTypeUpdateData
  ) => {
    return await updateProjectTypeMutation({ orgId, id: projectTypeId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const deleteProjectType = async (orgId: string, projectTypeId: string) => {
    return await deleteProjectTypeMutation({ orgId, id: projectTypeId }).unwrap().catch(handleError)
  }

  return {
    enableNotification,
    disableNotification,
    updateNotificationSettings,
    addProjectType,
    updateProjectType,
    deleteProjectType,
  }
}
